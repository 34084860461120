import * as THREE from 'three';
class SceneManager {
    constructor() {
        this.scene = new THREE.Scene();
        this.metadataMap = new Map();
    }
    // Method to get the singleton instance
    static getInstance() {
        if (!SceneManager.instance) {
            SceneManager.instance = new SceneManager();
        }
        return SceneManager.instance;
    }
    // Add an object to the scene and store metadata
    addObject(object, metadata = {}) {
        this.scene.add(object);
        this.metadataMap.set(object.uuid, metadata);
    }
    // Remove an object from the scene and clear its metadata
    removeObject(object) {
        this.scene.remove(object);
        this.metadataMap.delete(object.uuid);
    }
    // Get metadata for an object
    getMetadata(object) {
        return this.metadataMap.get(object.uuid);
    }
    // Set metadata for an object
    setMetadata(object, metadata) {
        if (this.metadataMap.has(object.uuid)) {
            this.metadataMap.set(object.uuid, metadata);
        }
    }
    // Getter for the scene
    getScene() {
        return this.scene;
    }
}
export default SceneManager;
