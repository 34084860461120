import { Mesh } from "three";
import * as THREE from 'three';
export default class Projectile extends Mesh {
    constructor(positionX, positionY, positionZ, direction, speed, size, ballColor) {
        const geometry = new THREE.SphereGeometry(size * 100, 32, 16).toNonIndexed();
        const material = new THREE.MeshBasicMaterial({ color: ballColor });
        super(geometry, material);
        this.position.set(positionX, positionY, positionZ);
        this.direction = direction;
        this.speed = speed;
        this.size = size;
        this.updatePosition = () => {
            if (this.position.y <= 0) {
                direction.reflect(new THREE.Vector3(0, 1, 0));
            }
            this.position.x += direction.x * speed * 100;
            this.position.y += direction.y * speed * 100;
            ;
            this.position.z += direction.z * speed * 100;
            ;
            if (speed > 0) {
                speed *= 0.999;
            }
        };
    }
}
