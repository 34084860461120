import * as THREE from 'three';
export function sphericalArrangement(radius, elements, center) {
    let outputArray = [];
    let phi = Math.PI * (3. - Math.sqrt(5)); // Golden angle in radians
    for (let i = 0; i < elements; i++) {
        let y = 1 - (i / (elements - 1)) * 2; // Correct distribution along y-axis
        let r = Math.sqrt(1 - y * y) * radius; // Use 'r' for radial distance on x-z plane
        let theta = phi * i;
        let x = Math.cos(theta) * r;
        let z = Math.sin(theta) * r;
        // Create new vector for point and add the center vector to it
        let point = new THREE.Vector3(x, y * radius, z).add(center);
        outputArray.push(point);
    }
    return outputArray;
}
